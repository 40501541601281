import { FC, ReactNode } from 'react';
import { useTheme } from '@/hooks/useTheme';
import { DarkModeContext, SetDarkModeContext } from '@/utils/themeContext';

export const DarkModeContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useTheme();
  return (
    <DarkModeContext.Provider value={theme}>
      <SetDarkModeContext.Provider value={setTheme}>
        {children}
      </SetDarkModeContext.Provider>
    </DarkModeContext.Provider>
  );
};
