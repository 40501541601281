import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import localFont from 'next/font/local';
import { useRouter } from 'next/router';

import '@/styles/globals.css';
import Head from 'next/head';
import { DarkModeContextProvider } from '@/layout/DarkModeContextProvider';

import { GoogleAnalytics } from '@next/third-parties/google';

const grotesque = localFont({
  src: [
    {
      path: '../public/fonts/basis-grotesque-regular-pro.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/basis-grotesque-regular-pro.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/basis-grotesque-bold-pro.woff',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/basis-grotesque-bold-pro.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--grotesque',
});

const App = ({ Component, pageProps = {} }: AppProps) => {
  const router = useRouter();
  const ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string;

  const microMarkupSchema = {
    '@context': 'https://schema.org',
    '@type': 'MedicalBusiness',
    name: 'Microkhim',
    image: '/favicon-32x32.png',
    url: 'https://www.microkhim.com.ua',
    telephone: '+380952869014',
    email: 'info@microkhim.com',
    priceRange: 'By Appointment',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'вул. Будіндустрії, 5',
      addressLocality: 'Київ',
      postalCode: '01013',
      addressCountry: 'UA',
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        closes: '17:00:00',
        dayOfWeek: 'https://schema.org/Monday',
        opens: '09:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        closes: '18:00:00',
        dayOfWeek: 'https://schema.org/Wednesday',
        opens: '09:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        closes: '18:00:00',
        dayOfWeek: 'https://schema.org/Tuesday',
        opens: '09:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        closes: '18:00:00',
        dayOfWeek: 'https://schema.org/Thursday',
        opens: '09:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        closes: '17:00:00',
        dayOfWeek: 'https://schema.org/Friday',
        opens: '09:00:00',
      },
    ],
  };

  return (
    <DarkModeContextProvider>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
        {router.locales &&
          router.locales.map(locale => {
            return (
              <link
                key={locale}
                rel="alternate"
                hrefLang={locale}
                href={
                  locale === 'en'
                    ? `https://www.microkhim.com.ua/${locale}${router.asPath}`
                    : `https://www.microkhim.com.ua${router.asPath}`
                }
              />
            );
          })}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(microMarkupSchema),
          }}
        />
      </Head>

      <div className={grotesque.className}>
        <Component {...pageProps} />
        <GoogleAnalytics gaId={ID} />
      </div>
    </DarkModeContextProvider>
  );
};

export default appWithTranslation(App);
